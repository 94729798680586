export default {
    data() {
        return{
            isOverlay: false,
            modal: {
                isActive: false,
                modals: null
            }
        }
    },
    methods: {
        modalOpen(modal) {
            this.modal.isActive = true
            this.isOverlay = true
            this.modals.forEach(el => {
                let mName = el.getAttribute("data-modal")
                if(modal == mName) {
                el.classList.add('active')
                }
                if(modal == 'video') {
                this.play()
                }
            })      
        },
        modalClose() {
            this.modal.isActive = false
            this.isOverlay = false
            this.modals.forEach(el => {
                el.classList.remove('active')
            })      
        }, 
    },
    mounted() {
        this.modals = document.querySelectorAll('.js-modal');
    }
}