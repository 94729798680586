<template>
  <div ref="app" id="app" :class="{'hidden': modal.isActive}">
    <div class="app-wrapper">
      <section class="section main" data-bg-img="">
        <div class="logo main-logo gs-logo">
          <img :src="images.logo" alt="Frau Danke">
        </div>
        <div class="main-graphics">
          <div class="glass bubble-scene gs-glass" id="bubble-scene"> 
            <div class="splash-wrapper">
              <svg class="splash gs-splash" width="103" height="99" viewBox="0 0 103 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.22381 62.2847C1.03956 56.2655 3.15199 49.9845 7.78003 43.7295C12.4065 37.4768 19.4568 31.3814 27.85 25.8273C44.6357 14.7197 66.5318 5.9471 84.3237 2.4466C88.7565 1.57447 92.1348 1.29842 94.6795 1.49313C97.2228 1.68773 98.8289 2.34375 99.8448 3.23607C100.844 4.11385 101.423 5.35327 101.646 7.04188C101.871 8.75393 101.717 10.8451 101.306 13.2938C100.897 15.7343 100.249 18.4412 99.5346 21.3748C99.4858 21.5751 99.4367 21.7763 99.3874 21.9786C98.7179 24.7238 98.0052 27.6466 97.4078 30.6538C96.1265 37.1037 95.3482 44.0643 96.6724 50.7946C97.2976 53.9724 97.9679 56.958 98.5944 59.7488L98.6457 59.9774C99.2885 62.8408 99.8802 65.489 100.336 67.9594C101.252 72.9249 101.583 77.0049 100.743 80.4439C99.9234 83.8027 97.9574 86.6635 93.9964 89.1444C89.9829 91.6582 83.9357 93.7693 75.0155 95.5243C57.2932 99.0111 40.2764 96.9611 27.1021 90.8893C13.9261 84.8167 4.68133 74.7755 2.22381 62.2847Z" fill="#DD213F" stroke="#DD213F" stroke-width="2.5"/>
              </svg>
              <svg class="splash gs-splash" width="103" height="99" viewBox="0 0 103 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.22381 62.2847C1.03956 56.2655 3.15199 49.9845 7.78003 43.7295C12.4065 37.4768 19.4568 31.3814 27.85 25.8273C44.6357 14.7197 66.5318 5.9471 84.3237 2.4466C88.7565 1.57447 92.1348 1.29842 94.6795 1.49313C97.2228 1.68773 98.8289 2.34375 99.8448 3.23607C100.844 4.11385 101.423 5.35327 101.646 7.04188C101.871 8.75393 101.717 10.8451 101.306 13.2938C100.897 15.7343 100.249 18.4412 99.5346 21.3748C99.4858 21.5751 99.4367 21.7763 99.3874 21.9786C98.7179 24.7238 98.0052 27.6466 97.4078 30.6538C96.1265 37.1037 95.3482 44.0643 96.6724 50.7946C97.2976 53.9724 97.9679 56.958 98.5944 59.7488L98.6457 59.9774C99.2885 62.8408 99.8802 65.489 100.336 67.9594C101.252 72.9249 101.583 77.0049 100.743 80.4439C99.9234 83.8027 97.9574 86.6635 93.9964 89.1444C89.9829 91.6582 83.9357 93.7693 75.0155 95.5243C57.2932 99.0111 40.2764 96.9611 27.1021 90.8893C13.9261 84.8167 4.68133 74.7755 2.22381 62.2847Z" fill="#DD213F" stroke="#DD213F" stroke-width="2.5"/>
              </svg>
              <svg class="splash gs-splash" width="103" height="99" viewBox="0 0 103 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.22381 62.2847C1.03956 56.2655 3.15199 49.9845 7.78003 43.7295C12.4065 37.4768 19.4568 31.3814 27.85 25.8273C44.6357 14.7197 66.5318 5.9471 84.3237 2.4466C88.7565 1.57447 92.1348 1.29842 94.6795 1.49313C97.2228 1.68773 98.8289 2.34375 99.8448 3.23607C100.844 4.11385 101.423 5.35327 101.646 7.04188C101.871 8.75393 101.717 10.8451 101.306 13.2938C100.897 15.7343 100.249 18.4412 99.5346 21.3748C99.4858 21.5751 99.4367 21.7763 99.3874 21.9786C98.7179 24.7238 98.0052 27.6466 97.4078 30.6538C96.1265 37.1037 95.3482 44.0643 96.6724 50.7946C97.2976 53.9724 97.9679 56.958 98.5944 59.7488L98.6457 59.9774C99.2885 62.8408 99.8802 65.489 100.336 67.9594C101.252 72.9249 101.583 77.0049 100.743 80.4439C99.9234 83.8027 97.9574 86.6635 93.9964 89.1444C89.9829 91.6582 83.9357 93.7693 75.0155 95.5243C57.2932 99.0111 40.2764 96.9611 27.1021 90.8893C13.9261 84.8167 4.68133 74.7755 2.22381 62.2847Z" fill="#DD213F" stroke="#DD213F" stroke-width="2.5"/>
              </svg>
              <svg class="splash gs-splash" width="103" height="99" viewBox="0 0 103 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.22381 62.2847C1.03956 56.2655 3.15199 49.9845 7.78003 43.7295C12.4065 37.4768 19.4568 31.3814 27.85 25.8273C44.6357 14.7197 66.5318 5.9471 84.3237 2.4466C88.7565 1.57447 92.1348 1.29842 94.6795 1.49313C97.2228 1.68773 98.8289 2.34375 99.8448 3.23607C100.844 4.11385 101.423 5.35327 101.646 7.04188C101.871 8.75393 101.717 10.8451 101.306 13.2938C100.897 15.7343 100.249 18.4412 99.5346 21.3748C99.4858 21.5751 99.4367 21.7763 99.3874 21.9786C98.7179 24.7238 98.0052 27.6466 97.4078 30.6538C96.1265 37.1037 95.3482 44.0643 96.6724 50.7946C97.2976 53.9724 97.9679 56.958 98.5944 59.7488L98.6457 59.9774C99.2885 62.8408 99.8802 65.489 100.336 67.9594C101.252 72.9249 101.583 77.0049 100.743 80.4439C99.9234 83.8027 97.9574 86.6635 93.9964 89.1444C89.9829 91.6582 83.9357 93.7693 75.0155 95.5243C57.2932 99.0111 40.2764 96.9611 27.1021 90.8893C13.9261 84.8167 4.68133 74.7755 2.22381 62.2847Z" fill="#DD213F" stroke="#DD213F" stroke-width="2.5"/>
              </svg>
              <svg class="splash gs-splash" width="103" height="99" viewBox="0 0 103 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.22381 62.2847C1.03956 56.2655 3.15199 49.9845 7.78003 43.7295C12.4065 37.4768 19.4568 31.3814 27.85 25.8273C44.6357 14.7197 66.5318 5.9471 84.3237 2.4466C88.7565 1.57447 92.1348 1.29842 94.6795 1.49313C97.2228 1.68773 98.8289 2.34375 99.8448 3.23607C100.844 4.11385 101.423 5.35327 101.646 7.04188C101.871 8.75393 101.717 10.8451 101.306 13.2938C100.897 15.7343 100.249 18.4412 99.5346 21.3748C99.4858 21.5751 99.4367 21.7763 99.3874 21.9786C98.7179 24.7238 98.0052 27.6466 97.4078 30.6538C96.1265 37.1037 95.3482 44.0643 96.6724 50.7946C97.2976 53.9724 97.9679 56.958 98.5944 59.7488L98.6457 59.9774C99.2885 62.8408 99.8802 65.489 100.336 67.9594C101.252 72.9249 101.583 77.0049 100.743 80.4439C99.9234 83.8027 97.9574 86.6635 93.9964 89.1444C89.9829 91.6582 83.9357 93.7693 75.0155 95.5243C57.2932 99.0111 40.2764 96.9611 27.1021 90.8893C13.9261 84.8167 4.68133 74.7755 2.22381 62.2847Z" fill="#DD213F" stroke="#DD213F" stroke-width="2.5"/>
              </svg>
              <svg class="splash gs-splash" width="103" height="99" viewBox="0 0 103 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.22381 62.2847C1.03956 56.2655 3.15199 49.9845 7.78003 43.7295C12.4065 37.4768 19.4568 31.3814 27.85 25.8273C44.6357 14.7197 66.5318 5.9471 84.3237 2.4466C88.7565 1.57447 92.1348 1.29842 94.6795 1.49313C97.2228 1.68773 98.8289 2.34375 99.8448 3.23607C100.844 4.11385 101.423 5.35327 101.646 7.04188C101.871 8.75393 101.717 10.8451 101.306 13.2938C100.897 15.7343 100.249 18.4412 99.5346 21.3748C99.4858 21.5751 99.4367 21.7763 99.3874 21.9786C98.7179 24.7238 98.0052 27.6466 97.4078 30.6538C96.1265 37.1037 95.3482 44.0643 96.6724 50.7946C97.2976 53.9724 97.9679 56.958 98.5944 59.7488L98.6457 59.9774C99.2885 62.8408 99.8802 65.489 100.336 67.9594C101.252 72.9249 101.583 77.0049 100.743 80.4439C99.9234 83.8027 97.9574 86.6635 93.9964 89.1444C89.9829 91.6582 83.9357 93.7693 75.0155 95.5243C57.2932 99.0111 40.2764 96.9611 27.1021 90.8893C13.9261 84.8167 4.68133 74.7755 2.22381 62.2847Z" fill="#DD213F" stroke="#DD213F" stroke-width="2.5"/>
              </svg>
              <svg class="splash gs-splash" width="103" height="99" viewBox="0 0 103 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.22381 62.2847C1.03956 56.2655 3.15199 49.9845 7.78003 43.7295C12.4065 37.4768 19.4568 31.3814 27.85 25.8273C44.6357 14.7197 66.5318 5.9471 84.3237 2.4466C88.7565 1.57447 92.1348 1.29842 94.6795 1.49313C97.2228 1.68773 98.8289 2.34375 99.8448 3.23607C100.844 4.11385 101.423 5.35327 101.646 7.04188C101.871 8.75393 101.717 10.8451 101.306 13.2938C100.897 15.7343 100.249 18.4412 99.5346 21.3748C99.4858 21.5751 99.4367 21.7763 99.3874 21.9786C98.7179 24.7238 98.0052 27.6466 97.4078 30.6538C96.1265 37.1037 95.3482 44.0643 96.6724 50.7946C97.2976 53.9724 97.9679 56.958 98.5944 59.7488L98.6457 59.9774C99.2885 62.8408 99.8802 65.489 100.336 67.9594C101.252 72.9249 101.583 77.0049 100.743 80.4439C99.9234 83.8027 97.9574 86.6635 93.9964 89.1444C89.9829 91.6582 83.9357 93.7693 75.0155 95.5243C57.2932 99.0111 40.2764 96.9611 27.1021 90.8893C13.9261 84.8167 4.68133 74.7755 2.22381 62.2847Z" fill="#DD213F" stroke="#DD213F" stroke-width="2.5"/>
              </svg>
              <svg class="splash gs-splash" width="103" height="99" viewBox="0 0 103 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.22381 62.2847C1.03956 56.2655 3.15199 49.9845 7.78003 43.7295C12.4065 37.4768 19.4568 31.3814 27.85 25.8273C44.6357 14.7197 66.5318 5.9471 84.3237 2.4466C88.7565 1.57447 92.1348 1.29842 94.6795 1.49313C97.2228 1.68773 98.8289 2.34375 99.8448 3.23607C100.844 4.11385 101.423 5.35327 101.646 7.04188C101.871 8.75393 101.717 10.8451 101.306 13.2938C100.897 15.7343 100.249 18.4412 99.5346 21.3748C99.4858 21.5751 99.4367 21.7763 99.3874 21.9786C98.7179 24.7238 98.0052 27.6466 97.4078 30.6538C96.1265 37.1037 95.3482 44.0643 96.6724 50.7946C97.2976 53.9724 97.9679 56.958 98.5944 59.7488L98.6457 59.9774C99.2885 62.8408 99.8802 65.489 100.336 67.9594C101.252 72.9249 101.583 77.0049 100.743 80.4439C99.9234 83.8027 97.9574 86.6635 93.9964 89.1444C89.9829 91.6582 83.9357 93.7693 75.0155 95.5243C57.2932 99.0111 40.2764 96.9611 27.1021 90.8893C13.9261 84.8167 4.68133 74.7755 2.22381 62.2847Z" fill="#DD213F" stroke="#DD213F" stroke-width="2.5"/>
              </svg>
            </div>           
            <div ref="bubbleScenes" class="beer gs-beer"></div>
            <img class="malt malt-1 gs-malt-1" :src="images.main.malt_1" alt="malt-1">
            <img class="malt malt-2 gs-malt-2" :src="images.main.malt_2" alt="malt-2">
            <img class="malt malt-3 gs-malt-3" :src="images.main.malt_3" alt="malt-3">
            <img class="malt malt-4 gs-malt-4" :src="images.main.malt_4" alt="malt-4">
            <img class="hops hops-1 gs-hops-1" :src="images.main.hops_1" alt="hops-1">
            <img class="hops hops-2 gs-hops-2" :src="images.main.hops_2" alt="hops-2">
            <div class="pot-wrapper gs-pot">
              <!-- <img class="pot" :src="images.main.pot.webp" alt="pot"> -->
              <!-- <img class="pot-shadow" :src="images.main.pot_shadow.webp" alt="pot"> -->
              <picture>
                <source type="image/webp" :srcset="images.main.pot.webp">
                <source type="image/png" :srcset="images.main.pot.png">
                <img class="pot" :src="images.main.pot.png" alt="pot">
              </picture>
              <picture>
                <source type="image/webp" :srcset="images.main.pot_shadow.webp">
                <source type="image/png" :srcset="images.main.pot_shadow.png">
                <img class="pot-shadow" :src="images.main.pot_shadow" alt="pot">
              </picture>              
            </div>
          </div>
        </div>
        <div class="main-info">
          <p class="i-s_bold yellow main_tagline gs-fadeIn" data-delay="0.2">Frau Danke 0%</p>
          <div class="main_title">
            <h1 class="i-black light gs-fadeIn" data-offset="20" data-delay="0.4">Удовольствие <br>со вкусом</h1>
            <div class="magnit main-magnit gs-magnit gs-fade">
              <img class="magnit-logo" :src="images.magnitLogo" alt="">
              <img class="magnit-text" :src="images.magnitText" alt="">
            </div>
          </div>          
          <p class="i-reg yellow main_subtitle gs-fadeIn" data-offset="20" data-delay="0.6">Frau Danke 0% - чистый солодовый немецкий лагер золотистого цвета с мягким сладко зерновым вкусом и легким горьковатым послевкусием. Тонкие пряные, цветочно-травяные хмелевые нотки и сдержанная горечь помогают балансу быть солодовым, но не сладким, благодаря чему пиво остается освежающим повседневным напитком.</p>
          <div class="contact-icons gs-fade">
            <i class="icon-youtube" @click.prevent="modalOpen('video')"></i>
            <i class="icon-mail" @click.prevent="modalOpen('form')"></i>            
          </div>
        </div>
        <div class="scroll-down gs-fade" data-scroll="#description" @click.prevent="scrollToAnchor">
          <i class="scroll-down__arrow icon-arrow"></i>  
        </div>
      </section>
      <section class="section description gs-description" id="description">
          <h2 class="i-e_Bold light section-title gs-fadeIn">Для создания пива Frau&nbsp;Danke&nbsp;0% мы используем:</h2>
          <div class="features">
            <div class="features-item gs-fadeIn" data-offset="40">
              <img class="features-item__icon" :src="images.description.features.icon_1" alt="">
              <p class="features-item__text i-med yellow">Дрожжи из банка дрожжей Weihenstephan <br>(Вайнштефан, Бавария, Германия)</p>
            </div>
            <div class="features-item gs-fadeIn" data-offset="40">
              <img class="features-item__icon" :src="images.description.features.icon_2" alt="">
              <p class="features-item__text i-med yellow">Артезианская вода, специально <br>подготовленная для производства пива</p>
            </div>
            <div class="features-item gs-fadeIn" data-offset="40">
              <img class="features-item__icon" :src="images.description.features.icon_3" alt="">
              <p class="features-item__text i-med yellow">Натуральный хмель европейских <br>производителей: Barth HAAS (Барт Хаас) - <br>Германия, Bogemia Hop (Богемия Хор) - Чехия <br>и ОАО «Чувашхмельпром» - Россия</p>
            </div>
            <div class="features-item gs-fadeIn" data-offset="40">
              <img class="features-item__icon" :src="images.description.features.icon_4" alt="">
              <p class="features-item__text i-med yellow">Солод премиальных сортов <br>от российских солодовенных компаний <br>«Суффле», «Глобал Тред»,<br> «АвангардАргоТрейд»</p>
            </div>
          </div>
          <div class="barrel">
            <div ref="bubbleScenes2" class="bubble-scene" id="bubble-scene-2"></div>
            <img class="malt malt-1 gs-b-malt-1" :src="images.description.malt_1" alt="malt-1">
            <img class="malt malt-2 gs-b-malt-2" :src="images.description.malt_2" alt="malt-2">
            <img class="malt malt-3 gs-b-malt-3" :src="images.description.malt_3" alt="malt-3">
            <img class="malt malt-4 gs-b-malt-4" :src="images.description.malt_4" alt="malt-4">
            <img class="hops hops-1 gs-b-hops-1" :src="images.description.hops_1" alt="hops-1">
            <img class="hops hops-2 gs-b-hops-2" :src="images.description.hops_2" alt="hops-2">    
            <picture>
              <source type="image/webp" :srcset="images.description.barrel.webp">
              <source type="image/png" :srcset="images.description.barrel.png">
              <img class="barrel-img gs-fadeIn" data-offset="40" data-delay="0.8" :src="images.description.barrel.png" alt="barrel">
            </picture>
            <!-- <img class="barrel-img gs-fadeIn" data-offset="40" data-delay="0.8" :src="images.description.barrel" alt="barrel"> -->
          </div>
      </section>  
      <footer class="footer gs-footer">
        <div class="footer-container">
          <div class="contact-icons footer-contact-icons">
            <i class="icon-youtube" @click.prevent="modalOpen('video')"></i>
            <i class="icon-mail" @click.prevent="modalOpen('form')"></i>            
          </div>
          <h3 class="footer-title i-black light">Ищите Frau Danke безалкогольное в сети Магнит</h3>
          <p class="footer-subtitle i-med brown">© 2021 Frau Danke безалкогольное</p> 
          <p class="footer-alcohol-info i-reg brown">менее 0.5% об. алкоголь</p>
          <div class="magnit footer-magnit">
            <img class="magnit-logo" :src="images.magnitLogo" alt="">
            <img class="magnit-text" :src="images.magnitText" alt="">
          </div>
          <div class="logo footer-logo">
            <img :src="images.logo" alt="Frau Danke">
          </div>
          <div class="herbs-wrapper">
            <img class="malt malt-1 gs-f-malt-1" :src="images.footer.malt_1" alt="malt-1">
            <img class="malt malt-2 gs-f-malt-2" :src="images.footer.malt_2" alt="malt-2">
            <img class="malt hops-1 gs-f-hops-1" :src="images.footer.hops_1" alt="hops-1">
            <img class="malt hops-2 gs-f-hops-2" :src="images.footer.hops_2" alt="hops-2">
          </div>          
        </div>
      </footer>        
    </div>     
    <div class="stamp gs-stamp">
      <img :src="images.stamp" alt="">
    </div> 
    <div class="modal video-modal js-modal" data-modal="video">
      <div class="modal-content video-content">
        <div class="video-wrapper">
          <video class="main-video" autoplay="true" playsinline muted @timeupdate="progressUpdate()" @canplaythrough="showElemVideo">
            <source :src="video.src" type="video/mp4">
          </video>  
          <span ref="video_info" class="video-el video-el__info i-med yellow">*Frau Danke безалкогольное</span>        
          <img ref="video_stamp" class="video-el video-el__stamp" :src="images.video.stamp" alt="stamp">
          <!-- <img ref="video_pot" class="video-el video-el__pot" :src="images.video.pot" alt="pot">-->
          <picture>
            <source type="image/webp" :srcset="images.video.pot.webp">
            <source type="image/png" :srcset="images.video.pot.png">
            <img ref="video_pot" class="video-el video-el__pot" :src="images.video.pot.png" alt="pot">
          </picture>
        </div>        
        <div ref="volume-control" class="video-volume-control" :class="{ 'mute' : video.mute }" @click.prevent="mute">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.81496 26.6667H3.33329C2.89127 26.6667 2.46734 26.4911 2.15478 26.1785C1.84222 25.8659 1.66663 25.442 1.66663 25V15C1.66663 14.558 1.84222 14.134 2.15478 13.8215C2.46734 13.5089 2.89127 13.3333 3.33329 13.3333H9.81496L18.6383 6.11333C18.7604 6.01324 18.9084 5.94989 19.0651 5.93065C19.2218 5.91141 19.3807 5.93708 19.5234 6.00466C19.666 6.07224 19.7866 6.17895 19.871 6.31238C19.9553 6.44581 20.0001 6.60046 20 6.75833V33.2417C20.0001 33.3995 19.9553 33.5542 19.871 33.6876C19.7866 33.821 19.666 33.9277 19.5234 33.9953C19.3807 34.0629 19.2218 34.0886 19.0651 34.0693C18.9084 34.0501 18.7604 33.9867 18.6383 33.8867L9.81663 26.6667H9.81496ZM32.3433 33.5567L29.9833 31.1967C31.5632 29.7909 32.8273 28.0661 33.692 26.1361C34.5567 24.2062 35.0025 22.1148 35 20C35.002 17.7768 34.5089 15.5811 33.5564 13.5723C32.6039 11.5635 31.2159 9.79205 29.4933 8.38666L31.86 6.01999C33.8908 7.73924 35.522 9.8808 36.6401 12.2953C37.7581 14.7098 38.3359 17.3392 38.3333 20C38.3333 25.3717 36.0233 30.2033 32.3433 33.5567ZM26.4383 27.6517L24.0683 25.2817C24.8774 24.6595 25.5326 23.8596 25.9831 22.9437C26.4337 22.0279 26.6676 21.0207 26.6666 20C26.6666 17.6167 25.4166 15.525 23.5333 14.3467L25.9316 11.9483C27.1941 12.877 28.2202 14.0897 28.9269 15.4885C29.6337 16.8873 30.0013 18.4328 30 20C30 23.07 28.6166 25.8167 26.4383 27.6517Z" fill="#FDD2AB"/>
          </svg>
        </div>        
        <p class="video-skip i-black light" @click.prevent="skip">Пропустить</p>        
      </div>
    </div>   
    <div class="modal form-modal js-modal" data-modal="form">
      <div class="modal-content form-content">        
        <div class="form-wrapper">
          <div class="modal-close" @click.prevent="modalClose"></div>          
          <ValidationObserver class="form" ref="form" tag="div" v-slot="{ handleSubmit }">
            <h2 class="form-title i-s_bold light">{{ !form.isSend ? 'Обратная связь' : 'Спасибо!' }}</h2>
            <form ref="form" v-show="!form.isSend" method="post" @submit.prevent="handleSubmit(onSubmit)" >
              <ValidationProvider tag="div" class="form-input" name="email" rules="required|email" vid="email" v-slot="{ classes }" slim>           
                <input v-model="form.formData.email" :class="classes" id="email" type="email" name="email">
                <label for="email" :class="{ 'checked': form.formData.email }">E-mail</label>
              </ValidationProvider>
              <ValidationProvider tag="div" class="form-textarea" name="message" rules="required|min:15" vid="message" v-slot="{ classes }" slim>
                <textarea v-model="form.formData.message" :class="classes" id="message" type="text" name="message"></textarea>
                <label for="message" :class="{ 'checked': form.formData.message }">Текст сообшения</label>
              </ValidationProvider>
              <span class="recaptch-info i-reg">Защита от спама reCAPTCHA <a href="https://policies.google.com/privacy">Конфиденциальность</a> и <a href="https://policies.google.com/terms">Условия использования</a></span>
              <vue-recaptcha
                ref="recaptcha"
                size="invisible"
                :sitekey="RECAPTCHA_SITE_KEY"
                @verify="register"
                @expired="onCaptchaExpired"
              />
              <button type="submit" class="form-button">Отправить</button>                         
            </form>
            <div v-show="form.isSend" class="send-messege">
              <p class="i-reg yellow">Спасибо за обратную связь мы обязательно с вами свяжемся!</p>
              <button type="button" class="form-button" @click.prevent="modalClose()">закрыть</button>   
            </div>
          </ValidationObserver>
        </div>        
      </div>
    </div>
    <div class="overlay" :class="{show: isOverlay}"></div>
  </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha'
import video from "@/mixins/video"
import modal from "@/mixins/modal"
import form from "@/mixins/form"
import animate from "@/mixins/animate"
import modernizr from "@/mixins/modernizr"
import { ValidationObserver, ValidationProvider, extend  } from "vee-validate";
import { required, email, min } from 'vee-validate/dist/rules';
extend('required', required)
extend("email", email)
extend("min", min)
export default {
  name: 'App',
  mixins: [video, animate, modal, form, modernizr],
  components: { ValidationObserver, ValidationProvider, VueRecaptcha },
  data() {
    return {   
      data: '',            
      images: {
        logo: require('@/assets/images/svg/logo.svg'),
        stamp: require('@/assets/images/svg/stamp.svg'),
        
        magnitLogo: require('@/assets/images/svg/magnit__logo.svg'),
        magnitText: require('@/assets/images/svg/magnit__text.svg'),        
        main: {
          pot: {
            png: require('@/assets/images/main/pot.png'),
            webp: require('@/assets/images/main/pot.webp'),
          }, 
          pot_shadow: {
            png: require('@/assets/images/main/pot-shadow.png'),
            webp: require('@/assets/images/main/pot-shadow.webp'),
          }, 
          malt_1: require('@/assets/images/main/malt-1.svg'),
          malt_2: require('@/assets/images/main/malt-2.svg'),
          malt_3: require('@/assets/images/main/malt-3.svg'),
          malt_4: require('@/assets/images/main/malt-4.svg'),
          hops_1: require('@/assets/images/main/hops-1.svg'),
          hops_2: require('@/assets/images/main/hops-2.svg')
        },
        description: {
          features: {
            icon_1: require('@/assets/images/features/icon-1.svg'),
            icon_2: require('@/assets/images/features/icon-2.svg'),
            icon_3: require('@/assets/images/features/icon-3.svg'),
            icon_4: require('@/assets/images/features/icon-4.svg')
          },
          barrel: {
            png: require('@/assets/images/barrel.png'),
            webp: require('@/assets/images/barrel.webp'),
          },
          malt_1: require('@/assets/images/barrel/b_malt-1.svg'),
          malt_2: require('@/assets/images/barrel/b_malt-2.svg'),
          malt_3: require('@/assets/images/barrel/b_malt-3.svg'),
          malt_4: require('@/assets/images/barrel/b_malt-4.svg'),
          hops_1: require('@/assets/images/barrel/b_hops-1.svg'),
          hops_2: require('@/assets/images/barrel/b_hops-2.svg')
        },
        footer: {
          malt_1: require('@/assets/images/footer/malt-1.svg'),
          malt_2: require('@/assets/images/footer/malt-2.svg'),
          hops_1: require('@/assets/images/footer/hops-1.svg'),
          hops_2: require('@/assets/images/footer/hops-2.svg')
        },
        video: {
          stamp: require('@/assets/images/svg/stamp-video.svg'),
          pot: {
            png: require('@/assets/images/pot-video.png'),
            webp: require('@/assets/images/pot-video.webp'),
          }
        }
      }
    }
  },
  methods: {  
    playAnimate() {
      this.logoAnimation()
      this.mainAnimations(this.$refs.bubbleScenes)
      this.maltAnimation_b()
      this.hopsAnimation_b()
      this.maltAnimation_f()
      this.hopsAnimation_f()
      this.bubbleAnimationBarrel(this.$refs.bubbleScenes2)
      this.fadeAnimation()
      this.fadeInAnimation()
    }
  },
  mounted() {
    this.modalOpen('video')
  }  
}
</script>

