import { gsap } from "gsap/dist/gsap.min";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin.min";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.min";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
export default {  
    data() {
        return {
            fadeElements: null,
            fadeInElements: null,
        }
    },
    methods: {
        logoAnimation() {
            gsap.from(".gs-logo", {y: -100 + '%', ease: "elastic.out(0.6,0.4)", delay: 0.1, duration: 3})
        },
        mainAnimations(scene) {
            const config = {count: 180, color: '#DD213F'}
            let dots = []   
            for (let i = 0; i < config.count; i++) {
                let dot = document.createElement("span");
                dot.setAttribute("class", "dot");
                dots.push(dot); 
                scene.appendChild(dot);
            }    
            gsap.set(".gs-splash", {opacity: 0.3, scale: 0,})
            function glass() {
                let tl = gsap.timeline()
                tl.from(".gs-glass", {opacity: 0, duration: 1}) // Появление круга
                tl.from(".gs-beer", {scale: 0,ease: "sine.inOutinOut", duration: 2.5}) // Заполнение круга  
                tl.to(".gs-splash", {scale: 1.15, opacity: 'random(0.8, 1)', rotate: 'random(-200, 200)', stagger: 0.3, duration: 3}, "<") // Волны 
                return tl
            }
            function pot() {
                let tl = gsap.timeline()
                tl.from(".gs-pot .pot", {opacity: 0, translateY: 40, ease: "elastic.out(1,0.4)", duration: 2}) // Появление банки
                tl.from(".gs-pot .pot-shadow", {opacity: 0, translateX: -40, duration: 0.5}, "-=1.5") // Появление тени банки
                return tl
            }
            function grass() {
                let tl = gsap.timeline()
                tl.from(".gs-malt-1", {opacity: 0, rotate: -45, translateX: 100,translateY: 150, ease: "elastic.out(1,0.4)", duration: 3})
                tl.from(".gs-malt-2", {opacity: 0, rotate: -90, translateX: 25,translateY: 100, ease: "elastic.out(1,0.4)", duration: 2}, "<")
                tl.from(".gs-malt-3", {opacity: 0, rotate: 95, ease: "elastic.out(1,0.4)", duration: 3}, "<")
                tl.from(".gs-malt-4", {opacity: 0, rotate: 95, ease: "elastic.out(1,0.4)", duration: 2}, "<")
                tl.from(".gs-hops-1", {opacity: 0, translateY: 200, translateX: -200, ease: "elastic.out(1,0.8)", duration: 3}, "<")
                tl.from(".gs-hops-2", {opacity: 0, translateY: -200, translateX: 200, ease: "elastic.out(1,0.8)", duration: 3}, "<")
                return tl
            }
            function bubble() {
                let tl = gsap.timeline({repeat:-1});   
                tl.fromTo(dots, 
                    {scale: 3.2, top: 50 + '%', left: 50 + '%', backgroundColor: `random([transparent], ${config.color})`},     
                    {scale: 0, translateX: 'random(-220, 220)', translateY: 'random(-250, 280)', duration: 3, stagger: 0.1})         
                return tl
            }
            let master = gsap.timeline()
            master.add(glass(), "glassLabel").add(pot(), "-=2.5").add(grass(), "<").add(bubble(), "glassLabel")
        },        
        maltAnimation_b() { 
            gsap.from(".gs-b-malt-1", {
                scrollTrigger: {
                trigger: '.barrel',
                start: "top 100%",
                },
                opacity: 0,
                rotate: -45,
                translateX: 100,
                translateY: 150,
                ease: "elastic.out(1,0.4)",
                delay: 3,
                duration: 3
            })
            gsap.from(".gs-b-malt-2", {
                scrollTrigger: {
                trigger: '.barrel',
                start: "top 100%",
                },
                opacity: 0,
                rotate: -90,
                translateX: 25,
                translateY: 100,
                ease: "elastic.out(1,0.4)",
                delay: 3,
                duration: 2
            })
            gsap.from(".gs-b-malt-3", {
                scrollTrigger: {
                trigger: '.barrel',
                start: "top 100%",
                },
                opacity: 0,
                rotate: 95,
                ease: "elastic.out(1,0.4)",
                delay: 3,
                duration: 3
            })
            gsap.from(".gs-b-malt-4", {
                scrollTrigger: {
                trigger: '.barrel',
                start: "top 100%",
                },
                opacity: 0,
                rotate: 95,
                ease: "elastic.out(1,0.4)",
                delay: 3,
                duration: 2
            })
        },
        hopsAnimation_b() {        
            gsap.from(".gs-b-hops-1", {
                scrollTrigger: {
                trigger: '.barrel',
                start: "top 100%",
                },
                opacity: 0,
                translateX: 200,
                translateY: 10,        
                ease: "elastic.out(1,0.8)",
                delay: 3,
                duration: 3
            })
            gsap.from(".gs-b-hops-2", {
                scrollTrigger: {
                trigger: '.barrel',
                start: "top 100%",
                },
                opacity: 0,
                translateX: -200,
                translateY: 20,        
                ease: "elastic.out(1,0.8)",
                delay: 3,
                duration: 3
            })
        },
        maltAnimation_f() { 
            ScrollTrigger.matchMedia({
                "(min-width: 641px)": function() {
                    gsap.from(".gs-f-malt-1", {
                        scrollTrigger: {
                            trigger: '.barrel',
                            start: "top 100%",
                        },
                        rotate: -120,
                        translateY: 50,
                        ease: "elastic.out(1,0.4)",
                        delay: 1,
                        duration: 3
                    })
                    gsap.from(".gs-f-malt-2", {
                        scrollTrigger: {
                            trigger: '.barrel',
                            start: "top 100%",
                        },
                        rotate: -100,
                        translateY: 50,
                        ease: "elastic.out(1,0.4)",
                        delay: 1,
                        duration: 3
                    })
                },
                "(max-width: 640px)": function() {
                    gsap.from(".gs-f-malt-1", {
                        scrollTrigger: {
                            trigger: '.barrel',
                            start: "top 100%",
                        },
                        rotate: -120,
                        translateX: -150,
                        ease: "elastic.out(1,0.8)",
                        delay: 1,
                        duration: 2
                    })
                    gsap.from(".gs-f-malt-2", {
                        scrollTrigger: {
                            trigger: '.barrel',
                            start: "top 100%",
                        },
                        rotate: -80,
                        translateX: -150,    
                        ease: "elastic.out(1,0.8)",
                        delay: 1,
                        duration: 2
                    })
                }              
            })           
        },
        hopsAnimation_f() {    
            gsap.from(".gs-f-hops-1", {
                scrollTrigger: {
                    trigger: '.barrel',
                    start: "top 100%",
                },
                translateY: 150,        
                ease: "elastic.out(1,0.8)",
                delay: 1,
                duration: 2
            })
            gsap.from(".gs-f-hops-2", {
                scrollTrigger: {
                    trigger: '.barrel',
                    start: "top 100%",
                },
                translateY: -150,        
                ease: "elastic.out(1,0.8)",
                delay: 1,
                duration: 2
            })
        },
        bubbleAnimationBarrel(scene) {
            let dots = [];
            const config = {
                count: 100,
                color: '#DD213F'
            }
            for (let i = 0; i < config.count; i++) {
                let dot = document.createElement("span");
                dot.setAttribute("class", "dot");
                dots.push(dot); 
                scene.appendChild(dot);
            }   
            let tl = gsap.timeline({
                scrollTrigger: {
                trigger: scene,
                start: "top 100%",
                },
                delay: 1.5,
                repeat:-1, 
                repeatDelay: 0
            });
            gsap.set(dots, {
                backgroundColor: `random([transparent], ${config.color})`,
                x: "random(-400, 400)",
                y: "random(-100, -250)",
                scale: 0,
                opacity: 1
            });
            tl.from(dots, {
                opacity: 0,
                duration: "random(3,5)",
                scale: 2.5,
                x: 'random(-200, 200)',
                y: 'random(50, 100)',
                stagger: 0.1
            })
        },
        fadeAnimation() {
            this.fadeElements.forEach(el => {      
                let delay = Number.parseFloat(el.getAttribute("data-delay")) 
                gsap.from(el, {
                scrollTrigger: {
                    trigger: el,
                    start: "bootom 90%",
                },
                opacity: 0,
                delay: delay || 1,
                duration: 1
                })
            });
        },
        fadeInAnimation(){
            this.fadeInElements.forEach(el => {
                let delay = Number.parseFloat(el.getAttribute("data-delay")) 
                let offset = Number.parseFloat(el.getAttribute("data-offset"))      
                gsap.from(el, {
                scrollTrigger: {
                    trigger: el,
                    start: "bootom 90%",
                },
                opacity: 0,
                y: offset || 20,
                delay: delay || 0.5,
                duration: 1
                })
            });
        },
        scrollToAnchor(e) {
            let node = e.target.getAttribute("data-scroll");
            gsap.to(window, {duration: 1, scrollTo: node});
        }         
    },
    mounted() {
        this.fadeElements = document.querySelectorAll(".gs-fade");
        this.fadeInElements = document.querySelectorAll(".gs-fadeIn");   
        ScrollTrigger.matchMedia({
            "(min-width: 600px)": function() {
                gsap.to(".gs-description", {
                scrollTrigger:{
                    trigger: ".footer",
                    start: 'top 95%',
                    scrub: true
                },
                y: 100,
                })
            },
            "(min-width: 800px)": function() {
                gsap.to(".gs-description", {
                scrollTrigger:{
                    trigger: ".footer",
                    start: 'top 90%',
                    scrub: true
                },
                y: 150
                })
            },
            "(max-width: 640px)": function() {
                gsap.to(".gs-stamp", {
                scrollTrigger:{
                    trigger: ".footer",
                    start: 'top 100%',
                    end: "top 100%-=431",
                    scrub: 0.2,
                },
                y: -431,
                })
            },
            "(min-width: 641px)": function() {
                gsap.to(".gs-stamp", {
                scrollTrigger:{
                    trigger: ".footer",
                    start: 'top 100%',
                    end: "top 100%-=286",
                    scrub: 0.2,
                },
                y: -286,
                })
            }
        })
    }
}