export default {
    data() {
        return{
            video: {
                elem: null,
                src: 'https://player.vimeo.com/external/586722296.hd.mp4?s=e8d43d66f45d370a83df937659d3f0b26ed8c803&profile_id=175',
                shown: false,
                isActive: true,
                mute: true
            },
        }
    },
    methods: {
        play() {
            this.video.elem.play()
        },
        stop() {
            this.video.elem.pause()
            this.video.elem.currentTime = 0  
        },
        mute(){
            if(this.video.mute) {
                this.video.mute = false
                this.video.elem.muted = false
            } else {
                this.video.mute = true
                this.video.elem.muted = true
            }      
        },
        skip() {
            this.stop()      
            this.modalClose()    
            if(!this.video.shown) {
                this.playAnimate()
                this.video.shown = true
            }  
        },
        progressUpdate(){
            if(this.video.elem.ended) {
                this.modalClose()
                this.stop()
                this.playAnimate()
            }
        },
        showElemVideo() {
            // this.$refs.video_stamp.style.opacity = 1
            // this.$refs.video_pot.style.opacity = 1
            // this.$refs.video_info.style.opacity = 1
        }
    },
    mounted() {
        this.video.elem = document.querySelector('.main-video');
    }
}