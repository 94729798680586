import axios from 'axios'
export default {
    data() {
        return{
            RECAPTCHA_SITE_KEY: '6LdQdQccAAAAANo164P8Dn3tJ_JmGgnki_BkI5OX',
            form: {
                isActive: false,
                isSend: false,
                formData: {
                    email: '',
                    message: ''
                }
            },
        }
    },
    methods: {
        async register (recaptchaToken) {
            try {
                // https://fraudanke.magnit.ru
                // https://danke.demo.ru.net
                // http://danke-php/
                axios.post('https://fraudanke.magnit.ru/send.php', {
                email: this.form.formData.email,
                message: this.form.formData.message,
                gRecaptchaResponse: recaptchaToken
                },  {
                headers: {
                    'Content-Type': 'application/json'
                }
                })
                this.form.isSend = true
                this.resetForm()
            } catch (error) {
                console.log(error);
            }      
        },
        validate () {
            this.$refs.recaptcha.execute()
        },
        onCaptchaExpired () {
            this.$refs.recaptcha.reset()
        },
        resetForm() {
            for (let key in this.form.formData) {
            this.form.formData[key] = ""
            }
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
            setTimeout(() => {
            this.form.isSend = false
            this.modalClose()
            }, 3000)
        },
    }
}